import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';
import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { convertIdToText, setDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils';
import apiPath from '@/services/apiPath';

export default class TradeListViewModel {
  constructor() {
    this.searchData = [
      {
        title:'법인명/상호',
        type: 'Selectbox',
        dataList:[],
        maxWidth:"300px",
        isFull:true,
        value:null
      }
    ]
    this.tradeData = {
      dataList: [],
      footData: {},
      boardData : {
        title:'거래내역',
        drawDataList: [
          {
            title:'승인일',
            width:'128px',
            value:'requestAt',
            valueCustom:[
              {
                type: 'value',
                value:'requestAt',
                filter:{
                  name:'convertDateFormat',
                  value:'YYYY-MM-DD'
                },
              },
              { 
                type: 'text',
                value: ' ('
              },
              {
                type: 'value',
                value:'requestAt',
                filter:{
                  name:'getDayToWeek',
                },
              },
              { 
                type: 'text',
                value: ')'
              },
            ],
            isAlignLeft: true,
            isMobileBlock: true,
          },
          {
            title:'거래내역 ID',
            width:'180px',
            value:'traceId',
            isEllip: true,
            isAlignLeft: true
          },
          {
            title:'종류',
            width:'86px',
            value:'status',
            filter:{
              name:'convertIdToText',
              value:'trade_type'
            },
            badge: {
              badgeColorFilter:{
                name:'convertIdToColor',
                dataListName:'trade_type'
              },
              badgeFill: false
            },
            isAlignLeft: true
          },
          {
            title:'승인금액',
            width:'',
            value:'requestAmount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true
          },
          {
            title:'수수료',
            width:'',
            value:'fee_price',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true,
            emptyValueText:'0'
          },
          {
            title:'부가세',
            width:'',
            value:'vatAmount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true,
            emptyValueText:'0'
          },
          {
            title:'정산금액',
            width:'',
            value:'requestAmount',
            filter:{
              name:'convertNumberToComma',
            },
            isAlignRight: true,
            emptyValueText:'0'
          },
          {
            title:'주분번호',
            width:'180px',
            value:'orderId',
            isEllip: true,
            isAlignLeft: true,
          },
        ],
        option:{
          isTotal: true,
          isSize: true,
          // rowIdValue: 'trade_id'
        },
        emptyDesc:'법인명/상호를 선택하고 검색해주세요'
      },
    };
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  init(){
    this.getStoreList();
  }
  onClickRow(rowId,rowData){
    // router.push({ name: 'SETTLE_SETTLE_DETAIL', params: { id: rowId, step : this.tabData.value } });
    // this.tradeDataDetail = rowData;
    // this.isOrderDetailPopup = true;
  }
  
  getData(){
    const resultData = [
      { 
        order_id:'order_id_22_023_12_0_3',
        payment_date:'2022-10-06T10:00:00',
        trade_id:'123963874963',
        trade_type:'PAYMENT',
        payment_price:'55000',
        fee_price:'-5000',
        tax_price:'-500',
        settle_price:'44500',
      },
      { 
        order_id:'order_id_22_023_12_0_2',
        payment_date:'2022-10-07T10:00:00',
        trade_id:'458847583836',
        trade_type:'CANCLE',
        payment_price:'-258000',
        fee_price:'25000',
        tax_price:'250',
        settle_price:'-22250',
      },
      { 
        order_id:'order_id_22_023_12_0_1',
        payment_date:'2022-06-06T10:00:00',
        trade_id:'838593583836',
        trade_type:'PAYMENT',
        payment_price:'30000',
        fee_price:'-3000',
        tax_price:'-300',
        settle_price:'26700',
      },
    ]
    const totalResultData = { 
      payment_price:'55000',
      fee_price:'-5500',
      tax_price:'-550',
      settle_price:'48950',
    }
    this.tradeData.dataList = resultData
    this.paginationData.totalCount = resultData.length;
    this.paginationData.totalPage = 1;

    this.tradeData.footData = totalResultData;
  }
  getSearchParams(){
    const searchData = this.searchParams;
    this.searchDataList.forEach(item => {
      if(item.id === 'dateRange'){
        searchData[item.startId] = item.value[0];
        searchData[item.endId] = item.value[1];
      }else{
        searchData[item.id] = item.value;
      }
    });
    return makeQueryStringByObject(searchData);
  }
  getStoreList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.SOTRE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      const dataList = setDataList(resultData,'id','name');
      this.searchData[0].dataList = dataList;
      this.searchData[0].value = dataList[0].id;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getTradeList(){
    const query = makeQueryStringByObject(this.searchParams)
    const path = `${apiPath.TRADE_LIST.format(this.searchData[0].value)}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.tradeData.dataList = resultData
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
      this.tradeData.boardData.emptyDesc = `${convertIdToText(this.searchData[0].value,this.searchData[0].dataList)}의 검색결과가 없습니다`;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  onSearch(){
    this.getTradeList();
  }
}