<template>
  <PageWithLayout>
    <Search
      :searchDataList.sync="viewModel.searchData"
      @onSearch="viewModel.onSearch()"/>
    <Board
      :boardData="viewModel.tradeData.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.tradeData.dataList"
      :footData="viewModel.tradeData.footData"
      :paginationData="viewModel.paginationData"
      :emptyDesc="viewModel.tradeData.boardData.emptyDesc"
      @onSearch="viewModel.onSearch()"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"/>
    <template v-slot:popup>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
// viewModel
import TradeListViewModel from '@/views/store/trade/viewModel/TradeListViewModel'

export default {
  name:'TradeList',
  components:{
    PageWithLayout,
    Search,
    Board,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new TradeListViewModel(),
    }
  },
}
</script>